
  import { Component, Prop } from 'vue-property-decorator'
  import Row from './row.vue'
  import { isValidNumber } from '@/utils/general'

@Component({
  components: {
    Row,
  },
})
  export default class Simple extends Row {
  @Prop({ type: Boolean, default: false }) hiddenLabel!: boolean;

  getText (text) {
    if (isValidNumber(text)) {
      return text
    }

    return text?.length ? text : '-'
  }
  }
