
  import { Component, Prop, Vue } from 'vue-property-decorator'

  import { Auto } from '@/entities/public'
  import { NotificationContent } from '@/store/notification/state'
  import { mapActions } from 'vuex'

@Component({
  methods: {
    ...mapActions('notification', ['loadNotification']),
  },
})
  export default class AutoHeader extends Vue {
  @Prop({ type: Object, required: true }) auto!: Auto;
  @Prop({ type: String, default: '' }) title!: string;
  @Prop({ type: String, default: '' }) km!: string;
  @Prop({ type: String, default: '' }) owner!: string;
  @Prop({ type: Object, default: () => ({ textColor: 'white', small: true }) }) chip?: Record<string, any>;

  loadNotification!: (obj: NotificationContent) => Promise<void>;

  get header () {
    const { auto: { title } } = this

    return `${title}`
  }

  get subHeader () {
    const { auto } = this
    return auto?.version?.version?.name
  }

  get status () {
    const { chip, auto: { status } } = this
    if (chip.hidden || !status || status.name === 'buying') return undefined

    return {
      value: status?.description,
      color: status?.color,
      ...chip,
    }
  }

  async copyToClipboard (key) {
    await this.loadNotification({
      // @ts-ignore
      message: `Valor copiado al portapapeles`,
      notificationType: 'success',
    })
    await navigator.clipboard.writeText(key)
  }
  }
